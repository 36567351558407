"use client";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./AppHeader.module.scss";
import Link from "next/link";
import clsx from "clsx";
import { useParams, usePathname, useRouter } from "next/navigation";
import { signOut } from "next-auth/react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  EllipsisVertical,
  Plus,
} from "lucide-react";
import { useSession } from "@/providers/Session";
import { useNav } from "@/providers/Nav";
import { Share } from "@/components/actions/Share";
import { Tooltip } from "@/components/feedback/Tooltip";
import ProfileMenu from "@/components/components/ProfileMenu";
import If from "@/components/common/If";
import ShareMenu from "@/components/components/ChallengeCard/ShareMenu";
import { ChallengeGrid } from "@/components/structure/ChallengeGrid";

/**
 *
 * AppHeader
 *
 */
const AppHeader = ({ user, ...props }) => {
  const Router = useRouter();
  const Pathname = usePathname();
  const Params = useParams();
  const { id, challengeIsPublic, challenge } = useSession();
  const { showHistory, setShowHistory } = useNav();
  const challengeId = Params.id || challenge;

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const navRef = useRef(null);

  const controlNavbar = useCallback(() => {
    if (typeof window !== "undefined") {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY || currentScrollY < 10) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 10) {
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY);
    }
  }, [lastScrollY]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isDownSwipe = distance > 50;
    const isUpSwipe = distance < -50;
    if (isDownSwipe) {
      setIsVisible(false);
    } else if (isUpSwipe) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let ticking = false;

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            controlNavbar();
            ticking = false;
          });
          ticking = true;
        }
      };

      window.addEventListener("scroll", onScroll, { passive: true });
      window.addEventListener("touchstart", onTouchStart, { passive: true });
      window.addEventListener("touchmove", onTouchMove, { passive: true });
      window.addEventListener("touchend", onTouchEnd, { passive: true });

      return () => {
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("touchstart", onTouchStart);
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchend", onTouchEnd);
      };
    }
  }, [controlNavbar]);

  return (
    <>
      <header
        ref={navRef}
        className={clsx(
          styles["app-header"],
          "fixed top-0 left-0 w-full z-[50] bg-white",
          ` ${isVisible ? "" : "transform -translate-y-full"}`
          // Pathname !== "/app/playground/v3" ? "bg-[#504c70]" : "bg-white"
        )}
        {...props}
      >
        <div className="grid grid-cols-3 items-center px-gutter py-4">
          <div>
            <If condition={Pathname !== "/app/playground/v3"}>
              <button
                className="flex items-center text-[#F50078] gap-2 px-2 py-1 border-[#F50078] border-2 rounded-lg uppercase text-sm font-bold leading-none hover:text-white hover:bg-[#F50078] transition-all duration-200"
                onClick={() => setShowHistory(!showHistory)}
              >
                History <ChevronsRight />
              </button>
            </If>
          </div>
          <div className="flex flex-col items-center justify-center gap-0">
            <Link href="/">
              <img src="/logo.png" alt="Thinknado Logo" width={180} />
            </Link>
            <p className="font-bold uppercase text-center leading-none text-sm lg:text-base hidden sm:block">
              Tornado Thinking Toolkit
            </p>
          </div>
          <nav className="flex items-center gap-4 justify-end">
            {user ? (
              <>
                {/* <Link href="/app" className="text-white">
              Dashboard
            </Link> */}

                {/* <ProfileMenu name={user?.name} image={user?.image} /> */}
                <ProfileMenu inApp>
                  <span
                    className={`${styles.avatar} flex items-center justify-center`}
                  >
                    <img
                      src={user?.image ? user?.image : "/default-profile.png"}
                      className="aspect-square rounded-full w-14 lg:w-full"
                    />
                  </span>
                </ProfileMenu>
              </>
            ) : (
              <Link href="/auth/sign-in" className="text-black">
                Sign In
              </Link>
            )}
          </nav>
        </div>

        <If
          condition={
            (Pathname === "/app" && challengeId) ||
            (Pathname !== "/app" && Pathname !== "/app/playground/v3")
          }
        >
          <div className="flex justify-between px-8 py-4 border-t border-b-black sticky bg-white top-0">
            <div></div>
            <div className="flex justify-end gap-6 items-center text-[#F50078]">
              {/* <Share isPublic={challengeIsPublic} id={id} /> */}

              <If
                condition={
                  (Pathname === "/app" && challengeId) ||
                  Pathname.includes("/app/challenges")
                }
              >
                <ShareMenu
                  id={challengeId}
                  initialIsPublic={challengeIsPublic}
                  isInList={false}
                />
              </If>
              <If condition={challengeId != "" || Pathname !== "/app"}>
                <Tooltip text="New Session">
                  <button
                    onClick={() => {
                      if (Pathname === "/app") {
                        location.reload();
                        return;
                      }
                      location.href = "/app";
                    }}
                    className="flex items-center text-[#F50078] gap-2 px-1 py-1 border-[#F50078] border-2 rounded-full uppercase text-sm font-bold leading-none hover:text-white hover:bg-[#F50078] transition-all duration-200"
                  >
                    <span className="sr-only">New</span>{" "}
                    <Plus className=" text-current" />
                  </button>
                </Tooltip>
              </If>
              {/* <Tooltip text="More">
              <EllipsisVertical className="w-8 h-8" />
            </Tooltip> */}
            </div>
          </div>
        </If>
      </header>
      <nav
        className={`w-[400px] max-w-full left-0 fixed h-screen z-[52] top-0 transition-all duration-300 ease-in-out ${
          showHistory ? "translate-x-0" : "translate-x-[-100%]"
        }`}
      >
        <div className="bg-[#000] h-full px-8 pb-[300px] overflow-auto text-white">
          <header className="sticky bg-[#000] pt-16 pb-4 top-0 flex justify-between z-[11]">
            <h3 className="text-4xl uppercase text-white">History</h3>
            <div></div>
            <div className="flex items-center">
              <button
                onClick={() => location.reload()}
                className="flex items-center text-white gap-2 px-2 py-1 border-pink border-2 rounded-lg uppercase text-sm font-bold hover:bg-pink hover:text-black transition-all duration-200"
              >
                New <Plus className="currentColor" />
              </button>
              <button
                className="absolute top-4 right-0 w-max z-100 flex items-center text-white gap-2"
                onClick={() => {
                  setShowHistory?.(!showHistory);
                }}
              >
                <span className="sr-only">Close</span>{" "}
                <ChevronsLeft className="text-pink" />
              </button>
            </div>
          </header>
          <ChallengeGrid challenges={user?.challenges || []} />
        </div>
      </nav>
    </>
  );
};

export default AppHeader;
