"use client";

import { useContext } from "react";
import { useState } from "react";
import { createContext, ReactNode } from "react";

interface NavContextType {
  showHistory?: boolean;
  setShowHistory?: (arg0: boolean) => void;
}

export const NavContext = createContext<NavContextType>({
  showHistory: false,
  setShowHistory: () => {},
});

interface NavProviderProps {
  children: ReactNode;
}

export const NavContextProvider = NavContext.Provider;

export function NavProvider({ children }: NavProviderProps) {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <NavContextProvider
      value={{
        showHistory,
        setShowHistory,
      }}
    >
      {children}
    </NavContextProvider>
  );
}

export function useNav() {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useNav must be used within a NavProvider");
  }
  return context;
}
